<template>
    <div>
        <div class="container-fluid p-0">
            <!-- top image bar -->
            <div class="hero-img">
                <Navbar :pages="pages" />
                <div class="container">
                    <!-- <div class="row">
                        <div class="col mt-3 mb-1">
                            <div class="d-flex justify-content-between">
                                <div class="menu-item" v-for="(m, index) in menu">
                                    <a :href="tags[index]"> {{ m }} </a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <img src="@/assets/logo.png" class="d-block mx-auto" style="height: 145px" />
                </div>
            </div>
        </div>

        <!-- content -->
        <div v-for="(q, r) in quarks" :key="q.id">
            <!-- rows -->
            <div v-if="r != combiRow[0] && r != combiRow[1]" :class="getRowClass(q)">
                <div class="row mb-3">
                    <div v-for="(c, index) in q.nrCols" :class="q.colStyle[index]">
                        <div id="content" v-html="q.columns[index]"></div>
                    </div>
                </div>
            </div>

            <!-- rows with combined background image -->
            <div class="bg-image">
                <div class="container">
                    <div class="row mb-3 align-items-center" v-if="r == combiRow[0]">
                        <div v-for="(c, index) in q.nrCols" :class="q.colStyle[index]">
                            <div :class="{ 'shadow rounded-lg bg-white': c >= 1 }" class="mx-0">
                                <div class="px-3" v-html="q.columns[index]" @click="click"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-3 align-items-center" v-if="r == combiRow[1]">
                        <div class="col-md-12">
                            <div class="row shadow rounded-lg bg-white no-gutters">
                                <div v-for="(c, index) in q.nrCols" :class="q.colStyle[index]">
                                    <div class="px-3" v-html="q.columns[index]" @click="click"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Hero from '@/components/Hero';
import Header from '@/components/Header.vue';
import Navbar from '@/components/NavbarX.vue';
import utils from '@/js/utils';

export default {
    data: () => ({
        menu: ['', 'COACHING', 'GASTLESSEN', 'WORKSHOPS', 'WINKELTJE', 'OVER...'],
        tags: [],
        combiRow: [],
    }),

    props: {
        route: String,
    },

    components: { Hero, Header, Navbar },

    computed: {
        quarks() {
            let qq = this.$store.quarks;
            // console.log('main.vue computed quarks', qq.length);
            if (qq.length > 0) {
                this.handleQuarks(qq);
                // console.log(qq);
                return qq;
            }
        },
        pages() {
            let pages = this.$store.pages;
            if (pages.length > 0) {
                return pages;
            }
        },
        page() {
            let p = this.route;
            if (p == '/') p = 'home';
            let page = this.$store.getPage(p);
            return page;
        },
    },

    created: function () {
        // console.clear();
        // var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
        // var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -5).replace('T', ' ');
        // console.log('main', localISOTime);
    },
    mounted() {
        // this.anchorHashCheck();
    },

    methods: {
        // anchorHashCheck() {
        //     console.log('this.anchorHashCheck', this.$route.hash, window.location.hash);

        //     // if (window.location.hash === this.$route.hash) {
        //     const el = document.getElementById(this.$route.hash.slice(1));
        //     if (el) {
        //         console.log(el.offsetTop);
        //         window.scrollTo(0, el.offsetTop);
        //     }
        //     // }
        // },

        handleQuarks(arr) {
            // console.log('arr', arr.length, arr);
            utils.sortByRank(arr);

            let h1count = 0;
            let rc = 0;
            this.combiRow = [];

            arr.forEach((q) => {
                // determine which column title belongs to
                if (q.title != null) {
                    let spl = q.title.split(';');
                    if (spl.length == 1) {
                        q.titleCol = 0;
                    } else {
                        q.title = spl[0];
                        q.titleCol = spl[1];
                    }
                }

                // new property - column styles
                if (!Array.isArray(q.cols)) q.cols = q.cols.split(';');
                q.colStyle = [];
                let i = 0;
                q.cols.forEach((style) => {
                    q.colStyle[i] = 'col-md-' + style;
                    i++;
                });

                // new property - number of columns
                q.nrCols = q.cols.length;

                // new property - columns as array
                q.columns = [];
                q.columns.push(q.col1);
                q.columns.push(q.col2);
                q.columns.push(q.col3);
                q.columns.push(q.col4);

                // new property - column configuration
                q.configs = [0, 0, 0, 0, 0, 0, 0, 0];
                for (let i = 0; i < q.configs.length; i++) {
                    let mask = 1 << i;
                    q.configs[i] = (q.config & mask) == mask ? 1 : 0;
                }

                // special config - find row numbers that have combi configs[7] set
                if (q.configs[7]) {
                    this.combiRow.push(rc);
                }
                rc++;

                // check for image
                // for (let i = 0; i < q.columns.length; i++) {
                //     console.log('config', q.config);
                //     let el = q.columns[i];
                //     if (el != null) {
                //     	if (el.indexOf('<img') != -1) {
                //     		let img = utils.strip_tags(el, '<img>');
                //     		if (q.config && 0x02 << i) q.columns[i] = utils.img_fluid(img);
                //     		if (!first) {
                //     			q.columns[i] = q.columns[i].replace('img-fluid', 'img-fluid yellow-top');
                //     		}
                //     		// console.log('img-fluid', q.columns[i]);
                //     	}
                //     }
                // }

                // check for h1 elements
                if (q.columns[0].indexOf('<h1>') != -1) {
                    let h1new = '<h1 id="section-' + h1count + '">';
                    this.tags.push('#section-' + h1count);
                    h1count++;
                    q.columns[0] = q.columns[0].replaceAll('<h1>', h1new);
                    // console.log(q.columns[0]);
                    // console.log('h1', h1new);
                }

                let trigger = '<p>***aanmelden***</p>';
                for (let i = 0; i < 4; i++) {
                    if (q.columns[i].indexOf(trigger) != -1) {
                        let pNew = '';
                        if (i == 0) {
                            pNew = '<p class="special mb-0">Ik geef mij op</p>';
                        } else {
                            pNew = '<p class="special">Ik geef mij op</p>';
                        }
                        q.columns[i] = q.columns[i].replace(trigger, pNew);
                    }
                }
            });
        },

        // getColumnClass(q, index) {
        //     let obj = '';

        //     // color and background per column
        //     // switch (index) {
        //     //     case 0:
        //     //         obj = { configColorTop: q.configs[index + 1], colorPurple: q.configs[index + 1] };
        //     //         break;
        //     //     case 1:
        //     //         obj = { configColorTop: q.configs[index + 1], colorOrange: q.configs[index + 1] };
        //     //         break;
        //     //     case 2:
        //     //         obj = { configColorTop: q.configs[index + 1], colorBlue: q.configs[index + 1] };
        //     //         break;
        //     //     case 3:
        //     //         obj = { configColorTop: q.configs[index + 1], colorGreen: q.configs[index + 1] };
        //     //         break;
        //     // }

        //     // responsive order
        //     if (q.configs[index + 1]) {
        //         obj = 'order-first order-sm-first order-md-last';
        //     }

        //     return obj;
        // },

        getRowClass(q) {
            let obj = q.configs[0] ? '' : 'container';
            return obj;
        },

        click(e) {
            const elt = e.target.closest('.special');
            if (elt) {
                console.log('button click');
            }
        },
    },
};
</script>

<style>
.hero-img {
    background-image: url('/uploads/oranje balk_Basis.jpg');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;
    height: 240px;
}

.menu-item {
    color: white;
    font-weight: bold;
    font-size: 17px;
}

.bg-image {
    background-image: url('/uploads/ondergrond workshops_Workshops.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 1rem;
}

.img-wide {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.img-fluid {
    width: 100%;
    height: auto;
}

.special {
    background-color: #ed672b;
    border-radius: 0 0 0.3rem 0.3rem;
    text-align: center;
    font-family: 'Roboto_Slab';
    font-size: 24px;
    color: white;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-left: -1rem;
    margin-right: -1rem;
    cursor: pointer;
}

/* #content {
	display: flex;
	flex-direction: column;
}

#content p img {
	align-self: flex-end;
} */
</style>
