<template>
    <div>
        <!-- header with logo and menu -->
        <div class="container">
            <div class="row py-4 no-gutters" v-if="pages != undefined">
                <!-- logo -->
                <div class="col-10 order-2 col-lg-4 order-lg-1">
                    <router-link to="/">
                        <img src="@/assets/logo.png" class="img-fluid py-1 py-lg-0 px-5 px-lg-0" />
                    </router-link>
                </div>
                <!-- menu -->
                <div class="col-1 col-lg-8 order-1 order-lg-2 my-auto">
                    <div class="float-right">
                        <Navbar :pages="pages"></Navbar>
                    </div>
                </div>
                <!-- dummy column -->
                <div class="col-1 order-3 d-block d-none-lg"></div>
            </div>
        </div>

        <!-- breadcrumbs -->
        <div class="container-fluid header mb-5">
            <div class="container">
                <div class="row align-items-center mh text-white">
                    <div class="col-md-8">
                        <h1 :class="{ 'h1-small': $route.name != '' }">
                            {{ $route.meta.title }}
                        </h1>
                    </div>
                    <div class="col-md-4 subTitle">
                        <span v-if="$route.name != ''">Home / </span>
                        <span v-if="$route.name != ''" class="font-weight-bold">{{ $route.meta.title }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Navbar from '@/components/Navbar.vue';

export default {
    data: () => ({
        subTitle: '',
    }),

    props: {
        pages: { type: Array },
    },

    components: { Navbar },
};
</script>

<style scoped>
.header {
    background-image: linear-gradient(90deg, #2bc10c 28%, rgba(43, 193, 12, 0.74) 87%);
}

.mh {
    min-height: 85px;
}


h1 {
    margin-bottom: 0;
}

.h1-small {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: bold;
}

.subTitle {
    font-family: 'OpenSans';
    font-size: 18px;
}
</style>
