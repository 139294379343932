<template>
    <div class="hero-img">
        <div class="container">
            <div class="row">
                <div class="col mt-3 mb-1">
                    <div class="d-flex justify-content-between">
                        <div class="menu-item" v-for="(m, index) in menu">
                            {{ m }}
                        </div>
                    </div>
                </div>
            </div>
            <img src="@/assets/logo.png" class="d-block mx-auto" />
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        menu: ['GASTLESSEN', 'WORKSHOPS', 'PERSOONLIJKE ONTWIKKELING', 'WINKELTJE', 'OVER...'],
    }),
    props: {
        propsObj: {
            header_image: { type: String },
            header_title: { type: String },
            header_quote: { type: String },
        },
    },
};
</script>

<style scoped>
.hero-img {
    background-image: url('/uploads/oranje balk_Basis.jpg');
    /* background-size: cover; */
    background-repeat: no-repeat;
    height: 240px;
}

.menu-item {
    color: white;
    font-weight: bold;
    font-size: 17px;
}
</style>
